import React from 'react';
import Text from '../../ui/Text';
import Anchor from '../../ui/Anchor';
import { NewsMetaWrap } from './news-meta.style';

const NewsMeta = ({ icon, text, path, ...restProps }) => {
  return (
    <NewsMetaWrap {...restProps}>
      <Anchor path={path}>
        {icon}
        <Text as="span">{text}</Text>
      </Anchor>
    </NewsMetaWrap>
  );
};

export default NewsMeta;
