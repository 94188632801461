import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import AuthorMeta from '../../components/blog/author-meta';
import BlogMeta from '../../components/newsletter/news-meta';
import Categories from '../../components/newsletter/categories';
import PostNav from '../../components/newsletter/post-nav/layout-two';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import Heading from '../../components/ui/Heading';
import Section, { Box, Col, Row } from '../../components/ui/Wrapper';
import Footer from '../../containers/Layout/Footer';
import Header from '../../containers/Layout/Header';
import {
  BlogInfo,
  BlogMedia,
  BlogNavigation,
  CategoryBox,
  HeaderMetaWrap,
  SingleBlogWrap,
  Thumbnail,
} from './news-template.stc';
import CourseInfo from '../../components/CourseInfo';
import CourseLinkButton from '../../components/CourseLinkButton';
import { Description } from '../../components/CourseInfo/styles';
import BoxLargeImage from '../../components/box-large-image/layout-three';
import VideoPlayer from '../../components/VideoPlayer';

const NewsTemplate = ({ data, pageContext, location, ...restProps }) => {
  const post = data.contentfulNews;
  const { next = {}, previous = {} } = pageContext;
  const { sectionStyle, titleStyle, headerMetaStyle } = restProps;
  return (
    <>
      <Header />
      <SEO title={post.title} />
      <main className="site-wrapper-reveal">
        <Section {...sectionStyle}>
          <Row>
            <Col lg={12}>
              <BlogMedia>
                <Thumbnail>
                  <Img fluid={post.image.fluid} alt={post.title} />
                </Thumbnail>
              </BlogMedia>
            </Col>
          </Row>
          <Row>
            <Col lg={8} ml="auto" mr="auto">
              <SingleBlogWrap>
                <BlogInfo>
                  <CategoryBox>
                    <Categories categories={post.category} />
                  </CategoryBox>
                  <Heading {...titleStyle}>{post.title}</Heading>
                  <HeaderMetaWrap>
                    {post.author && <AuthorMeta {...headerMetaStyle} slug={post.author.slug} name={post.author.name} />}
                    {post.createdAt && <BlogMeta {...headerMetaStyle} text={post.createdAt} icon={<FaCalendarAlt />} />}
                  </HeaderMetaWrap>
                </BlogInfo>
                <Description>
                  <section
                    id="session1"
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: post.contentSession1.childContentfulRichText.html }}
                  />
                </Description>
                <Row>
                  <Col lg={12} md={12} className="box-item">
                    <BoxLargeImage imageSrc={post.imageSession1} path={post.imageLinkSession1} />
                  </Col>
                </Row>
                <Description>
                  <div
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: post.contentSession2.childContentfulRichText.html }}
                  />
                </Description>
                <Row>
                  <Col lg={12} md={12} className="box-item">
                    <BoxLargeImage imageSrc={post.imageSession2} path={post.imageLinkSession2} />
                  </Col>
                </Row>
                <Description>
                  <div
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: post.contentSession3.childContentfulRichText.html }}
                  />
                </Description>
                <Row>
                  <Col lg={12} md={12} className="box-item">
                    <BoxLargeImage imageSrc={post.imageSession3} path={post.imageLinkSession3} />
                  </Col>
                </Row>
                <Description>
                  <div
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: post.contentSession4.childContentfulRichText.html }}
                  />
                </Description>
                {/* {post.videoLink ? <VideoPlayer url={post.videoLink} description={post.videoDescription} /> : null}
                {post.segmentDescription ? (
                  <CourseLinkButton
                    path={post.segmentLinkAction}
                    description={post.segmentDescription}
                    buttonDescription={post.segmentLinkDescription}
                  />
                ) : null} */}
                <Box as="footer">
                  <BlogNavigation>
                    {previous && <PostNav rel="prev" path={`noticias/${previous.slug}`} title={previous.title} />}
                    {next && <PostNav rel="next" path={`noticias/${next.slug}`} title={next.title} />}
                  </BlogNavigation>
                </Box>
              </SingleBlogWrap>
            </Col>
          </Row>
        </Section>
      </main>
      <Footer />
    </>
  );
};

NewsTemplate.propTypes = {
  sectionStyle: PropTypes.object,
  data: PropTypes.shape({
    contentfulNews: PropTypes.shape({
      createdAt: PropTypes.string,
      image: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

NewsTemplate.defaultProps = {
  sectionStyle: {
    pt: '30px',
    pb: '120px',
    responsive: {
      large: {
        pt: '20px',
        pb: '100px',
      },
      medium: {
        pt: '15px',
        pb: '80px',
      },
      small: {
        pt: '10px',
        pb: '60px',
        fullwidth: true,
      },
    },
  },
  titleStyle: {
    as: 'h1',
    fontSize: '40px',
    textalign: 'center',
    mb: '15px',
    wordwrap: 'break-word',
    responsive: {
      medium: {
        fontSize: '35px',
      },
      small: {
        fontSize: '25px',
      },
    },
  },
  headerMetaStyle: {
    mt: '10px',
    mr: '20px',
  },
};

export const postQuery = graphql`
  query($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      id
      title
      slug
      category {
        slug
        title
        createdAt
      }
      author {
        name
        slug
      }
      createdAt(formatString: "LL", locale: "pt-BR")
      contentTitle
      image {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      videoLink
      videoDescription
      segmentLinkAction
      segmentLinkDescription
      segmentDescription
      contentSession1 {
        childContentfulRichText {
          html
        }
      }
      imageSession1 {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imageLinkSession1
      contentSession2 {
        childContentfulRichText {
          html
        }
      }
      imageSession2 {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imageLinkSession2
      contentSession3 {
        childContentfulRichText {
          html
        }
      }
      imageSession3 {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      imageLinkSession3
      contentSession4 {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;

export default NewsTemplate;
